import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useSwiper } from "../hooks/useSwiper"
import { Container, BaseButton } from "../styledComponents"
import { MediumMobileFont } from "../typography"
import SliderControls from "./sliderControls"
import BaseImg from "../baseImg"

const StyledBaseButton = styled(BaseButton)`
  margin-top: 1rem;
`
const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Slider = styled.div`
  display: flex;
  margin-bottom: 60px;
`

const SlideBox = styled.div`
  padding: 40px 72px;
  display: flex;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  @media (max-width: 1195px) {
    padding: 32px;
    flex-direction: column;
  }
`

const SlideTechnologies = styled.div`
  font-weight: 600;
  line-height: 2.5rem;
  margin-left: 2rem;
  width: 150px;
  flex-shrink: 0;
  @media (max-width: 1195px) {
    flex-wrap: wrap;
    margin: 1rem 0;
    flex-shrink: 1;
    width: auto;
    display: flex;
    justify-content: center;
  }
`

const SlideContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 0 10px;
  @media (max-width: 1195px) {
    flex-direction: column;
  }
`

const SlideHeader = styled.h3`
  margin-bottom: 0.75rem;
  ::first-letter {
    text-transform: capitalize;
  }
`
const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1rem 0 -2rem 0;
  padding: 2rem 0;
  @media (max-width: 1195px) {
    padding: 0;
    margin: 0;
    align-items: center;
  }
`
const Categories = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 1195px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  .category {
    border: none;
    background: none;
    cursor: pointer;
    margin: 2rem;
    color: ${(props) => props.theme.primary};
    font: 700 1.5rem/1.8125rem "Montserrat";
    @media (max-width: 1195px) {
      margin: 0.5rem 2rem;
    }
    :focus {
      outline: none;
    }
  }
  .category.active {
    text-decoration: line-through ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
  }
`

const SlideImg = styled(BaseImg)`
  width: 60%;
  margin: 0 -35% 0 0;
  @media (max-width: 1195px) {
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`

const SubservicesSlider = ({ subservices }) => {
  const innerWidth = useRef(0)
  useEffect(() => {
    innerWidth.current = window.innerWidth
  }, [])
  const {
    swiperElement,
    swiperInstance,
    activeSlideIndex,
  } = useSwiper(subservices, { spaceBetween: 32 })

  return (
    <Container>
      <Categories>
        {subservices.map((subservice, index) => (
          <button
            type="button"
            aria-label={`Go to ${subservice.title} slide`}
            onClick={() => swiperInstance.current.slideToLoop(index)}
            className={
              activeSlideIndex === index ? "active category" : "category"
            }
            key={subservice.title}
          >
            {subservice.title}
          </button>
        ))}
      </Categories>
      <div
        style={{ marginBottom: innerWidth.current < 900 ? "1rem" : "-2rem" }}
      >
        <SliderControls
          position="right"
          swiperInstance={swiperInstance}
          activeSlideIndex={activeSlideIndex}
          slides={subservices}
        />
      </div>
      <Slider>
        <div className="swiper-container" ref={swiperElement}>
          <ul style={{ margin: "0" }} className="swiper-wrapper">
            {subservices.map((subservice) => {
              console.log(subservice)
              const {
                title,
                text,
                technologies,
                buttonUrl,
                buttonText,
              } = subservice
              return (
                <Slide className="swiper-slide" key={title}>
                  <SlideContent>
                    <SlideImg localFile={subservice.image?.localFile} alt="" />
                    <SlideText>
                      <SlideBox>
                        <div>
                          <SlideHeader>
                            <MediumMobileFont>{title}</MediumMobileFont>
                          </SlideHeader>
                          <p
                            dangerouslySetInnerHTML={{ __html: text }}
                            style={{ lineHeight: "1.625", marginBottom: "0" }}
                          ></p>
                        </div>
                        <SlideTechnologies>
                          {technologies.map((technology) => (
                            <div
                              key={technology}
                              style={{
                                margin: "0 1rem",
                                overflowWrap: "normal",
                              }}
                            >
                              {technology}
                            </div>
                          ))}
                        </SlideTechnologies>
                      </SlideBox>
                      {buttonText && (
                        <StyledBaseButton size="small" to={buttonUrl}>
                          {buttonText}
                        </StyledBaseButton>
                      )}
                    </SlideText>
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
    </Container>
  )
}

export default SubservicesSlider
