import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import LastCTASection from "../components/lastCTASection"
import ListSection from "../components/listSection"
import ProjectsSection from "../components/projectsSection"
import SubservicesSlider from "../components/sliders/subservicesSlider"
import ClientsSlider from "../components/sliders/clientsSlider"
import { useAnimatedBorder } from "../helpers"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import {
  Container,
  BorderedHeader,
  BaseButton,
  SideBox,
} from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import BorderedHeaderWrapper from "../components/borderedHeaderWrapper"
import { BigMobileFont, MediumMobileFont } from "../components/typography"
import BaseImg from "../components/baseImg"

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 120px);
  margin-bottom: 70px;
  @media (max-width: 1000px) {
    height: auto;
  }
`
const HeroFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`
const HeroImg = styled(BaseImg)`
  width: 33%;
  @media (max-width: 900px) {
    width: 75%;
  }
`

const SingleService = ({ pageContext }) => {
  const {
    clientsSectionTitle,
    firstSectionButtonText,
    firstSectionButtonUrl,
    firstSectionText,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    listSectionTitle,
    projectsSectionTitle,
    secondSectionText,
    secondSectionTitle,
    subservices,
    list,
    firstSectionImage,
  } = pageContext.metaboxes
  const [scale, boxRef] = useAnimatedBorder(2)

  return (
    <Layout>
      <SEO
        meta={pageContext.yoast_meta}
        title={decodeHtmlCharCodes(pageContext.title)}
        path={pageContext.path}
      />
      <Container size="medium">
        <HeroSection>
          <HeroFlexWrapper>
            <HeroImg
              localFile={firstSectionImage ? firstSectionImage.localFile : null}
              alt=""
            />

            <SideBox scale={scale} ref={boxRef}>
              <h1 style={{ marginBottom: "0.75rem" }}>
                <BigMobileFont desktopFontSize="2.5rem">
                  {decodeHtmlCharCodes(pageContext.title)}
                </BigMobileFont>
              </h1>
              <p dangerouslySetInnerHTML={{ __html: firstSectionText }}></p>
            </SideBox>
          </HeroFlexWrapper>
          {firstSectionButtonText && (
            <BaseButton
              style={{ fontWeight: "600", padding: "1rem 4rem" }}
              to={firstSectionButtonUrl}
            >
              {firstSectionButtonText}
            </BaseButton>
          )}
        </HeroSection>
        <div
          style={{
            boxShadow: "0px 3px 6px #00000029",
            background: "#fff",
            maxWidth: "644px",
            padding: "38px 45px",
            textAlign: "center",
            margin: "0 auto 70px",
            lineHeight: "1.625",
          }}
        >
          <h2 style={{ marginBottom: "1rem" }}>{secondSectionTitle}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: secondSectionText }}
            style={{ marginBottom: "0" }}
          ></p>
        </div>
      </Container>
      <SubservicesSlider subservices={subservices} />
      <ListSection title={listSectionTitle} items={list} position="center" />
      <Container>
        <FlexColumn>
          {/* Needed for Projects Section mobile order to work */}
          <BorderedHeaderWrapper side="right">
            <BorderedHeader whiteSpace="nowrap" side="right">
              <BigMobileFont desktopFontSize="2.5rem">
                {projectsSectionTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <ProjectsSection />
        </FlexColumn>
      </Container>
      <CenterBorderedHeader size="small">
        <MediumMobileFont>{clientsSectionTitle}</MediumMobileFont>
      </CenterBorderedHeader>
      <Container size="medium">
        <ClientsSlider showMultipleClients />
      </Container>
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default SingleService
